import React, { useState,useEffect } from "react";




import { useLocation } from "react-router-dom";

import { Helmet } from "react-helmet";
import Navbar from "../Component/Navbar/Navbar";
import { Footer } from "../..";



export default function PrivacyPolicy() {
    useEffect(() => {
      // Scroll to the top of the page when the component mounts
      window.scrollTo(0, 0);
  
      // Optionally, you can also scroll to the top when the component unmounts
      return () => {
        window.scrollTo(0, 0);
      };
    }, []);
    return (
      <>
      <Helmet>
        <title>{"Privacy Policy | Unzziptruth.com"}</title>
        <meta
          name="description"
          content={
            "Learn how we safeguard your information and ensure your privacy on Unzziptruth.com. Explore our transparent privacy policy for peace of mind."
          }
        />
        {/* <meta name="keywords" content={blogData?.keywords} /> */}
      </Helmet>
     <Navbar/>
  
        <div style={{ width: "100vw"}}>
          <div className="container" style={{}}>
            <h2 style={{ textAlign: "center", fontSize: "30px" }}>
              Privacy Policy
            </h2>
            <p style={{ fontSize: "15px", padding: "20px 10px" }}>
              www.unzziptruth.com (“we”, “Unzzip Truth”, “Unzzip Truth” (web and
              application) hereinafter referred as “website”) is committed to
              protect the privacy of the users of the website (including
              astrologers and buyers/customers whether registered or not
              registered). Please read this privacy policy carefully to understand
              how the website is going to use your information supplied by you to
              the Website.
              <br />
              <br />
              This Privacy Policy is published in accordance with Rule 3(1) of the
              Information Technology (Intermediaries Guidelines) Rules, 2011 and
              Information Technology (Reasonable Security Practices and Procedures
              and Sensitive Personal Data or Information) Rules, 2011 which
              requires publishing of the Privacy policy for collection, use,
              storage and transfer of sensitive personal data or information.
              <br />
              <br />
              <strong>USER’S CONSENT</strong>
              <br />
              <br />
              This Privacy Policy, which may be updated/amended from time to time,
              deals with the information collected from its users in the form of
              personal identification, contact details, birth details and any
              forecast made using the supplied information and how such
              information is further used for the purposes of the Website. By
              accessing the website and using it, you indicate that you understand
              the terms and expressly consent to the privacy policy of this
              website. If you do not agree with the terms of this privacy policy,
              please do not use this website.
              <br />
              <br />
              Your continued use of this website shall confirm that you have
              provided your unconditional consent and confirm to the terms of this
              privacy policy as regards collecting, maintaining, using, processing
              and disclosing your personal and other information in accordance
              with this Privacy Policy.
              <br />
              <br />
              This Privacy Policy is to be read alongwith the respective Terms of
              Use or other terms and conditions as provided on the Website.
              <br />
              <br />
              <strong>Collection of Personal Information</strong>
              <br />
              <br />
              Creating a user profile with Unzzip Truth involves providing
              specific information. The mandatory details include your phone
              number for OTP (One-Time Password) verification, which is necessary
              to ensure the security and validity of the registration process.
              Additionally, you are required to provide your first name, last
              name, and date of birth (DOB) are optional. The date of birth can be
              considered an optional detail, which means it's not mandatory to
              provide it during the registration process.
              <br />
              <br />
              <strong>PURPOSE AND USE OF DATA/INFORMATION COLLECTION</strong>
              <br />
              <br />
              By collecting this information, Unzzip Truth aims to create a
              personalized user profile that can cater to your specific needs and
              preferences. However, if you choose not to provide your date of
              birth, it won't hinder the registration process, and you can still
              access the services offered by Unzzip Truth with your verified phone
              number.
              <br />
              <br />
              <strong>Data Deletion</strong>
              <br />
              Delete Profile: If you wish to delete your entire Unzzip Truth
              profile, including personal information associated with it, you
              might find an option to "Delete your account" Click on this setting
              option in side menu and follow the instructions and complete the
              account deletion process. You can reach us for deletion of your data
              by visiting <a href="/contact-us">Contact-us</a> page
              <br />
              <br />
              <strong>Camera and gallery permission</strong>
              <br />
              <br />
              In our app we have given feature to user to change profile photo
              from camera or gallery so we are asking grant us this permission
              <br />
              <br />
              <strong>Voice Recording and Microphone Permission</strong>
              <br />
              <br />
              In our app, we've implemented a unique and convenient way for you to
              ask questions and interact with us in your chat — by recording your
              voice as audio. Instead of typing out your queries, you also have an
              option to simply speak into your device's microphone, record the
              voice as audio, and then send it on chat. This audio interaction
              feature is designed to make using our app more intuitive and in tune
              with natural communication.
              <br />
              <br />
              To enable you to record your questions and thoughts in your own
              voice as audio, we need to request access to your device's
              microphone. When you grant us this permission, it allows our app to
              capture the sound of your voice as audio and convert it into digital
              data that we can process
              <br />
              <br />
              <strong>COMMITMENT</strong>
              <br />
              <br />
              The Website intends to protect the privacy of all kinds of users
              visiting the platform irrespective whether the user is a registered
              user or merely a visitor. It is recommended to every user to
              understand what types of personally identifiable information is
              collected. The Website employs the personally identifiable
              information for certain predictions however it is guaranteed that no
              direct or indirect use of such information which is revealed in the
              prediction for a member will be done except for the explicit purpose
              of communicating the horoscope charts and predictions to the member
              itself disclosing such information. It is further clarified that the
              Website does not in any manner deal in selling or renting the
              information supplied to the Website.
              <br />
              <br />
              The Website does not commit to treat or provide solutions for users
              with weak mental health which is inclusive of any user who have
              thoughts related to committing suicide, self-destruction etc. Such
              users are advised to stop the use of the present website with
              immediate effect and any continued use of the website by such person
              would be considered solely at the user’s risk and the Website shall
              have no liability for any untoward event in such scenario. The
              Website declares that the information provided by such kind of user
              can be shared, if required, with law enforcement authorities. Such
              information is not protected from any kind of non-disclosure or
              confidential agreements either with the Website or with any
              thirdparty involved herein.
              <br />
              <br />
              The Website does not commit in any manner whatsoever for the
              accuracy of the predictions made by the astrologers to any user. The
              Website does not take any guarantee/responsibility/liability
              regarding the reliability or reality of the gems and other related
              items represented and sold on the website. It is further declared by
              the Website that no warranty on such service is provided by the
              Website in any manner.
              <br />
              <br />
              <strong>INFORMATION COLLECTED BY WEBSITE</strong>
              <br />
              <br />
              PERSONAL IDENTIFIABLE INFORMATION: The information qualifies as
              personal in nature when the information collected identifies a
              specific end user. Such information would be collected by the
              website during the following actions:-
              <br />
              <br />
              <ul>
          <li className='fs-6'>
                  Creating an account / Registration data: While accessing the
                  Website, the User of the Website may be required to create an
                  account. The personal information which may be sought while
                  creating an account shall include, but not limited to the Full
                  name, Address, Telephone Number, Email-address, Date of Birth,
                  Gender, Location, Photograph, any other items of ‘sensitive
                  personal data or information” as such term is defined under the
                  Information Technology (Reasonable Security Practices And
                  Procedures And Sensitive Personal Data Of Information) Rules,
                  2011 enacted under the Information Technology Act, 2000, and any
                  other detail required on the website during registration. It is
                  hereby informed to all the Users that the e-mail address or
                  phone number together with a password or OTP is used for the
                  purpose of securing User’s profile and for effective
                  implementation of the personalized E-mail and SMS Services
                  provided by the Website to the User. In the event that no
                  registration is made by the User, the Website may not be able to
                  provide any services due to non-availability of the personal
                  identifiable information of the User.
                </li>
                <br />
          <li className='fs-6'>
                  Booking a paid service: While booking a service through Order
                  Form, the personal information which may be sought would
                  include, but not limited to the information as mentioned in
                  Column 1(a), financial information inclusive of bank account
                  information, credit card or debit card details or other payment
                  instrument details through a secure third party gateway, IP
                  (Internet protocol) Address and any other information that a
                  User may provide during booking a paid service on the Website.
                  Such information is kept highly confidential.
                </li>
                <br />
          <li className='fs-6'>
                  Log Files, IP Address and Cookies: The website collects
                  information that is stored by your browser on your computer’s
                  hard drive i.e. through cookies. It further automatically log
                  generic information about the user’s computer connection to the
                  Internet i.e. Session Data. The website may store temporary or
                  permanent ‘cookies’ on the user’s computer. Cookies would allow
                  the web server to recognize the user computer each time the user
                  returns to the website including the time and date of the visit,
                  viewing of page, length of time, verify registration or password
                  information etc. Such cookies are usually only read by the
                  server placed and the user may choose to block these cookies on
                  their computers. Please note that if the cookies are turned off,
                  the user may be prevented from using certain features of the
                  website. The website uses the cookies to personalize the user’s
                  experience on the website and to display an advertisement
                  according to the user’s preferences. Some of the services
                  provided by the Website may direct the User to platform of third
                  parties. Any Information provided by the User on such platforms
                  may be dealt by them in the manner provided by the privacy
                  policy formulated by such third-party platforms. The Website in
                  this regard fully disclaims any liability(ies) or claim(s) which
                  may arise by use/misuse of such information shared by the User,
                  to any third party or any party not known to the Website. The
                  website would not liable for the mis-use of such information
                  shared by the User or by any third party. <br />
                  <br />
                  We also collect details including but not limited to User
                  feedback, comments, etc. that may be
                  disclosed/informed/mentioned on any article/blog or
                  groups/forums or other pages which the User may have access to
                  while visiting the Website. For such information which is in
                  public domain and accessible to all the Users and visitors of
                  the Website, the User is advised to exercise its discretion
                  before disclosing it as this information is susceptible to
                  misuse
                </li>
                <br />
          <li className='fs-6'>
                  Miscellaneous Activities: The Website may collect any other
                  information which may be mandatory to be disclosed and further
                  may receive any other information via email or other method
                  inclusive of contract with regard to specific services availed
                  from the Website or any products bought from the Website, such
                  information may not be made part of the UserMember’s Profile but
                  shall be used only for addressing the specific need or concern
                  of the User
                </li>
              </ul>
              <br />
              <br />
              NON - PERSONAL IDENTIFIABLE INFORMATION: The information qualifies
              as non-personal in nature when the information collected does not
              identify a specific end user. Such information is collected when the
              user visits the Website, cookies, etc. and would include but not
              limited to the following:
              <br />
              <br />
              <ul>
          <li className='fs-6'>
                  URL (Uniform Resource Locator) of the previous website visited
                  by the User before visiting this website or the URL of the
                  website the User visits after visiting this Website.
                </li>
                <br />
          <li className='fs-6'>
                  Internet service provider / IP Address / Telecom service
                  provider.
                </li>
                <br />
          <li className='fs-6'>Type of Browser used for accessing the website.</li>
                <br />
          <li className='fs-6'>Geographical Location</li>
              </ul>
              <br />
              <br />
              Such non-personal identifiable information is used by the Website
              for the purposes including but not limited to troubleshoot
              connection problems, administer the website, analyze trends, gather
              demographic information, frequency of visits to the website, average
              length of visits, pages viewed during a visit, compliance with
              applicable law, and cooperate with law enforcement activities, etc.
              <br />
              <br />
              The information is used for improving the site content and
              performance and the website may share this information with Third
              Party Service Providers and Third Party Advertisers to measure the
              overall effectiveness of the website’s online advertising, content,
              programming and for other bonafide purpose as required.
              <br />
              <br />
              THE USER HEREBY REPRESENT AND CONFIRMS THAT THE INFORMATION PROVIDED
              TO THE WEBSITE IS AUTHENTIC, CORRECT, CURRENT AND UPDATED. THE
              WEBSITE AND ITS ENTITES SHALL NOT BE RESPONSIBLE FOR THE
              AUTHENTICITY OF THE INFORMATION THAT THE USER MAY PROVIDE. THE USER
              SHALL BE PERSONALLY LIABLE AND INDEMNIFY THE WEBSITE FOR THE BREACH
              OF ANY PROVISION.
              <br />
              <br />
              SECURITY MEASURES: The security of the personal information supplied
              by the User is very important to the Website and the website for the
              purpose of securing the information takes various measures inclusive
              of taking reasonable steps such as physical and electronic security
              measures to guard against the unauthorized access to the
              information. The personal information of a user is collected on a
              secured server. The payment details are entered on the Payment
              Gateway’s or Bank’s page on a secured SSL. The data is transferred
              between Bank’s page and payment’s gateways in an encrypted manner.
              However please note that no data transmission can be guaranteed to
              be completely secure. Hence the user is advised to take precaution
              and care against any sharing of the details submitted on the website
              included the log-in details as generated after registration. The
              website is not responsible for the security or confidentiality of
              communications the user may send through the internet using email
              messages, etc.
              <br />
              <br />
              USAGE OF THE INFORMATION: The information collected by the Website
              may be used for any purpose as may be permissible under the
              applicable law and shall include but not limited to the following: -
              <br />
              <br />
              <ul>
          <li className='fs-6'>
                  For providing a personalised browsing experience. While
                  guaranteeing the anonymity of the user, the personal information
                  collected in Clause “Personal Identifiable Information” may be
                  used for research purposes, for improving the marketing and
                  promotional efforts, to analyse usage, improve the content of
                  the Website, product offering and for customising the Website’s
                  layout for suiting the needs of its Users.
                </li>
                <br />
          <li className='fs-6'>
                  With IP tracking details and Cookies data, the Website will use
                  it only for facilitating the usage of the website and provide
                  personalised experience and any information which is sensitive
                  in nature will not be provided to any third party without the
                  consent of the User.
                </li>
                <br />
          <li className='fs-6'>
                  All information (and copies thereof) collected by Website,
                  including without limitation Personal Information, User Data,
                  and other information related to your access and use of the
                  services offered by Website, may be retained by Website for such
                  period as necessary, including but not limited to, for purposes
                  such as compliance with statutory or legal obligations, tax laws
                  and potential evidentiary purposes and for other reasonable
                  purposes such as to implement, administer, and manage your
                  access and use of our services, or resolution of any disputes.
                </li>
                <br />
          <li className='fs-6'>
                  To ensure a seamless experience at the Website for you and to
                  ensure your maximum benefit and comfort, the Website may use the
                  data collected through cookies, log file, device identifiers,
                  location data and clear gifs information to: (a) remember
                  information so that you will not have to re-enter it during your
                  visit or the next time you visit the site; (b) provide custom,
                  personalized content and information, including advertising; (c)
                  provide and monitor the effectiveness of Services offered by
                  Website; (d) monitor aggregate metrics such as total number of
                  visitors, traffic, usage, and demographic patterns on the
                  Website and its Services; (e) diagnose or fix technology
                  problems; and (f) otherwise to plan for and enhance the service.
                </li>
                <br />
          <li className='fs-6'>
                  Website uses certain third-party analytics tools to measure
                  traffic and usage trends for the Services. These tools collect
                  information, which is not personal or sensitive in nature sent
                  by the User’s device, including the web pages visited, add-ons,
                  and other information that assists the Website in improving the
                  Services. Such information is collected from Users in the form
                  of anonymized logs, so that it cannot reasonably be used to
                  identify any particular individual User.
                </li>
              </ul>
              <br />
              <br />
              CONFIDENTIAL: The website aspires to takes care of all the
              information provided to it by its Users which may be termed as
              confidential. Such confidential information which is not required to
              be disclosed to the website, is specifically excluded from the
              definition of Personal Information and shall not be collected/used.
              The confidential information of the User shall not be disclosed or
              shared by the Websites, its employees, its agents or any third-party
              contractors including the experts either orally or in writing except
              for the following circumstances:
              <br />
              <br />
              <ul>
          <li className='fs-6'>
                  If Website believes that there is a significant/ real/ imminent
                  threat or risk to User’s health, safety or life or to the
                  health, safety or life of any other person or the public.
                </li>
                <br />
          <li className='fs-6'>
                  If such confidential information must be shared in accordance
                  with the law inclusive of any investigation, Court summons,
                  judicial proceedings etc.
                </li>
                <br />
  
          <li className='fs-6'>
                  To protect and defend the rights or property of the Website
                </li>
              </ul>
              <br />
              <br />
              CHILDREN PRIVACY POLICY: The Website requires that the User visiting
              and using the services are above 18 years of age however some
              service information is accessible to children under the age of 18 as
              well. However, it is stressed upon that website is not designed or
              intended to be attractive to be used by children under the age of 13
              and no personal identifiable information of children below the age
              of 13 is collected knowingly. IF YOU ARE UNDER 13 YEARS OF AGE,
              PLEASE DO NOT USE ANY OF THE SERVICE PROVIDED BY THE WEBSITE AT ANY
              TIME OR IN ANY MANNER. If it comes to the knowledge of the concerned
              parent regarding sharing of any information of a child under the age
              of 13, contact the Website immediately. We will take appropriate
              steps and delete the data from the Website’s systems.
              <br />
              <br />
              Safety and Security: Unzzip Truth.com honors users' privacy and
              employs the best practice to secure the user's personal details,
              such as birth details, address, etc., and also financial details
              such as credit card or debit card transaction details. Unzzip
              Truth.com uses the best encryption methodologies to ensure secure
              transactions from our side and thus encourages our clients to use
              their credit/debit cards on Unzzip Truth.com with full confidence.
              By doing so, we strive for the safety and security of our users,
              thus making their experience with Unzzip Truth.com absolutely safe
              and secure.
            </p>
          </div>
        </div>
        <Footer/>
      </>
    );
  };