// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loading_loading__ADoVX {\n    aspect-ratio: 1;\n    height: 30px;\n    border: 3px solid var(--white);\n    border-top: 3px solid var(--dark);\n    border-radius: 50%;\n    animation: Loading_spin__8op2i 2s alternate infinite;\n}\n\n@keyframes Loading_spin__8op2i {\n    0% {\n        transform: rotate(0deg);\n    }\n\n    100% {\n        transform: rotate(360deg);\n    }\n}", "",{"version":3,"sources":["webpack://./src/Components/Component/Loading/Loading.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,8BAA8B;IAC9B,iCAAiC;IACjC,kBAAkB;IAClB,oDAAqC;AACzC;;AAEA;IACI;QACI,uBAAuB;IAC3B;;IAEA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".loading {\n    aspect-ratio: 1;\n    height: 30px;\n    border: 3px solid var(--white);\n    border-top: 3px solid var(--dark);\n    border-radius: 50%;\n    animation: spin 2s alternate infinite;\n}\n\n@keyframes spin {\n    0% {\n        transform: rotate(0deg);\n    }\n\n    100% {\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "Loading_loading__ADoVX",
	"spin": "Loading_spin__8op2i"
};
export default ___CSS_LOADER_EXPORT___;
